<template>
  <div class="main-header p-d-flex p-ai-center p-jc-between">
    <div class="p-d-flex p-ai-center p-jc-between" style="height: 100%">
      <navigation-menu></navigation-menu>
      <div class="breadcrumbs">
        <span v-for="(item, index) in breadcrumbs" :key="item.path">
          <span
            v-if="index !== 0"
            style="margin-left: 0.25rem; margin-right: 0.25rem"
          >
            >
          </span>
          <span class="breadcrumb-item" @click="followBreadcrumbs(index)">
            {{ item.name }}
          </span>
        </span>
      </div>
    </div>
    <div class="p-d-flex p-jc-end p-ai-center">
      <div class="p-d-flex p-ai-center p-jc-end">
        <slot></slot>
      </div>
      <OmniSearchBox></OmniSearchBox>
      <Notification class="top-notification"></Notification>
      <UserInfo class="top-user-info"></UserInfo>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import NavigationMenu from "@/components/NavigationMenu.vue";
import OmniSearchBox from "@/components/OmniSearchBox.vue";
import UserInfo from "@/components/UserInfo.vue";
import { capitalize } from "@vue/shared";
@Component({
  components: {
    NavigationMenu,
    OmniSearchBox,
    UserInfo,
  },
})
export default class TopBar extends Vue {
  get breadcrumbs() {
    const path = this.$route.path;
    return path
      .split("/")
      .map((item) => {
        let name = capitalize(item.replace("/", "").replace("-", " "));
        if (name.toLowerCase() == "youtube") name = "YouTube";
        return {
          name,
          path: item,
        };
      })
      .filter((item) => item.name);
  }

  async followBreadcrumbs(index) {
    await this.$router.push(
      "/" +
        this.breadcrumbs
          .slice(0, index + 1)
          .map((item) => item.path)
          .join("/")
    );
  }
}
</script>

<style>
.breadcrumbs {
  margin-left: 1.25rem;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--white);
}

.breadcrumb-item {
  cursor: pointer;
}

.breadcrumb-item:hover {
  color: var(--pale-grey-two);
}
</style>
