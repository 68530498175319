<template>
  <div style="display: inline-block">
    <avatar
      :size="30"
      id="user-info-trigger"
      class="p-mr-3"
      :src="me.picture"
      :username="currentUser.name"
    />
    <div ref="userInfoContent">
      <div class="p-d-flex p-jc-between p-ai-center p-p-4">
        <div class="p-d-flex p-ai-center">
          <router-link
            :to="`/manage/managers/${me.id}`"
            style="text-decoration: none; !important"
          >
            <avatar
              :size="40"
              class="p-mr-3"
              :src="me.picture"
              :username="currentUser.name"
            />
          </router-link>
          <div>
            <router-link
              :to="`/manage/managers/${me.id}`"
              style="text-decoration: none"
            >
              <p class="user-title p-text-bold p-text-uppercase">
                {{ currentUser.name }}
              </p>
            </router-link>
            <ul class="p-d-flex user-roles">
              <li class="user-role">
                {{ currentUser.role }}
              </li>
            </ul>
          </div>
        </div>
        <Button
          icon="pi pi-sign-out"
          class="p-ml-5 p-button-rounded p-button-text"
          @click="logout"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import "tippy.js/themes/light.css";

import { Component, Vue } from "vue-property-decorator";
import { readMaxScope, readMe } from "@/store/auth/getters";

import { logOut } from "@/helpers/auth";
import tippy from "tippy.js";

@Component
export default class UserInfo extends Vue {
  async logout() {
    await logOut();
    await this.$router.push("/login");
  }

  get me() {
    return readMe(this.$store);
  }

  get maxScope() {
    return readMaxScope(this.$store);
  }

  get currentUser() {
    return {
      name: [this.me.first_name, this.me.last_name].join(" ") || "Unregistered",
      email: this.me.email,
      role: this.maxScope,
    };
  }

  mounted() {
    // @ts-ignore
    tippy("#user-info-trigger", {
      theme: "light",
      placement: "bottom-start",
      interactive: true,
      trigger: "click",
      content: this.$refs.userInfoContent,
    });
  }
}
</script>

<style scoped>
.user-info {
  margin-top: 20px;
}
.user-info,
.user-info a {
  color: #409eff;
}

.user-info a {
  text-decoration: none;
}

.user-title {
  color: #409eff;
  font-size: 21px;
  margin-bottom: 0;
}

.user-roles {
  flex-shrink: 0;
  list-style: none;
  font-size: 0.75rem;
  padding-inline-start: 0;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: capitalize;
}

.user-role:after {
  content: "|";
  margin: 0 0.5rem;
}

.user-role:last-child:after {
  content: "";
}
</style>
