<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.19"
    height="25.111"
    viewBox="0 0 26.19 25.111"
  >
    <g transform="translate(0.5)">
      <g class="a" transform="translate(12.635 0) rotate(45)">
        <rect class="b" width="17.756" height="17.756" rx="4.53" />
        <rect
          class="c"
          x="0.5"
          y="0.5"
          width="16.756"
          height="16.756"
          rx="4.03"
        />
      </g>
      <path
        class="a"
        d="M211,258.246V237.178l25.19,21.257V237.178Z"
        transform="translate(-211 -235.25)"
      />
      <line class="a" y2="20" transform="translate(12.5 2.5)" />
    </g>
  </svg>
</template>

<style scoped>
.a,
.c {
  fill: none;
}

.a {
  stroke: white;
  stroke-miterlimit: 10;
}

.b {
  stroke: none;
}
</style>
