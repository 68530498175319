<template>
  <div id="v-step-3" class="p-input-icon-left omni-search-box">
    <autocomplete
      :search="search"
      placeholder="Search..."
      aria-label="Search"
      :debounceTime="300"
      :autoSelect="true"
      base-class="autocomplete-search"
      @submit="goToItem($event)"
      ref="omni-autocomplete"
    >
      <template #result="{ result, props }">
        <li v-bind="props" class="autocomplete-result">
          <div class="p-d-flex">
            <div
              class="p-mr-auto"
              style="overflow-x: hidden; text-overflow: ellipsis"
            >
              {{
                result.full_name ||
                result.name ||
                result.channel_name ||
                result.email
              }}
            </div>
            <div class="p-ml-1 p-mr-0">
              <span :class="'p-tag p-tag-' + colorRefs[result.type]">
                {{ result.type }}
              </span>
            </div>
          </div>
        </li>
      </template>
    </autocomplete>
    <i class="pi pi-search omni-search-icon p-mr-2" />
  </div>
</template>

<script lang="ts">
import "@trevoreyre/autocomplete-vue/dist/style.css";

import { Component, Vue } from "vue-property-decorator";

import Autocomplete from "@trevoreyre/autocomplete-vue";
import { readGeneralFuse } from "@/store/main/getters";

@Component({
  components: {
    Autocomplete,
  },
})
export default class OmniSearchBox extends Vue {
  get generalFuse() {
    return readGeneralFuse(this.$store);
  }

  public colorRefs = {
    Creator: "success",
    Manager: "primary",
    Account: "danger",
  };

  public search(query) {
    if (query) {
      // clean up query
      query = query.toLowerCase().trim();
      query = query
        .replace("https://", "")
        .replace("http://", "")
        .replace("www.", "")
        .replace("youtube.com/", "");
      const results = this.generalFuse.search(query);
      // return the top 10 results and omit non-true values
      return results
        .map((item) => item.item)
        .filter((item) => item)
        .slice(0, 10);
    }
    return [];
  }

  public goToItem(event) {
    const type = event.type.toLowerCase().replace(" ", "-") + "s";
    let url: string;
    if (type == "accounts") {
      url = `/mastersheet/${event.id}`;
    } else {
      url = `/manage/${type}/${event.id}`;
    }
    this.$refs["omni-autocomplete"]["value"] = undefined;
    let link = document.createElement("a");
    link.target = "_blank";
    link.href = url;
    link.click();
  }
}
</script>

<style>
.omni-search-box {
  width: 20rem;
  margin-left: 1.25rem;
  font-family: SourceSansPro, sans-serif;
  font-weight: normal;
  border: var(--cloudy-blue);
  border-radius: 20px;
  height: 1.9rem;
  z-index: 4;
}

.autocomplete-search input {
  width: 100%;
  padding-left: 2rem;
  background: #ebeef5;
  border: none;
  border-radius: 20px;
  height: 1.9rem;
}

.autocomplete-search input:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

.autocomplete-search-result-list {
  background: white;
  width: 20rem;
  list-style-type: none;
  padding: 0;
  margin-top: 0.2rem;
  border-radius: 4px;
  font-size: 0.85rem;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}

.autocomplete-search-result {
  border-radius: 4px;
  cursor: pointer;
}
</style>
