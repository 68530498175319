



































































































































































































































.navigation {
  height: 100%;
  overflow: visible;
}

.navigation-menu .p-sidebar-content {
  overflow: visible !important;
}

.menu-item-wrapper {
  position: relative;

  .menu-item-children-wrapper {
    color: var(--white);
    font-weight: bold;
    vertical-align: center;
    background-color: var(--dark-grey);
    position: absolute;
    left: 100%;
    top: 0;
    min-width: max-content;
    visibility: hidden;
    transition: visibility 0.3s linear, opacity 0.3s linear;

    .navigation-item {
      min-width: max-content;
      padding-right: 1rem;
    }
  }

  &:hover {
    .menu-item-children-wrapper {
      visibility: visible;
    }
  }
}

.logo-button-text {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.39px;
  vertical-align: middle;
  color: var(--white);
  margin-left: 0.75rem;
}

.sidebar-header {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 100%;
  color: var(--white);
  font-weight: bold;
  vertical-align: center;
  background-color: var(--dark-grey);
  width: 11.5rem;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.sidebar-header-open {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 3.4rem;
  color: var(--white);
  font-weight: bold;
  vertical-align: center;
  background-color: var(--dark-grey);
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  box-shadow: none;
  background-color: var(--rat-grey);
}

.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  height: 35px;
  width: 35px;
  background: transparent;
}

.navigation .p-sidebar.p-component.p-sidebar-left.p-sidebar-active {
  padding: 0;
  width: 15rem;
  background-color: var(--dark-grey);
  color: var(--white);
}

.navigation-icon {
  fill: white;
  font-size: 1.25rem;
  height: 1.25rem;
  margin-left: 0.63rem;
  margin-right: 1rem;
}

.navigation-icon-child {
  @extend .navigation-icon;
  margin-left: 0rem;
}

.navigation-item {
  height: 3.4rem;
  padding-left: 1rem;
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.4px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.navigation-item:hover {
  background-color: rgb(31%, 36.5%, 45.9%, 50%);
}

.navigation-item-active {
  background-color: var(--rat-grey);
}

.navigation .p-sidebar .p-sidebar-content {
  padding: 0;
}

.navigation .p-sidebar .p-sidebar-header {
  padding: 0;
}
